// @flow
import React from 'react';
import HandleDeeplink from 'src/components/HandleDeeplink';
import { useLocation } from '@reach/router';

const CardActivation = () => {
  const location = useLocation();
  const activationCode = location.pathname.split('/')[3];
  const deepLink = `card/activation/${activationCode}`;

  return <HandleDeeplink deeplink={deepLink} redirect="/" />;
};

export default CardActivation;
